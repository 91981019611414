const placesMichoacan = [
    {
        id: "1",
        img: "./assets/HospitalAngeles-michoacan.jpg",
        name: "Hospital Angeles",
        alt: "Hospital Angeles",
        direction: "Av Montaña Monarca 331-Norte, Desarrollo Montaña Monarca, 58350 Morelia, Mich."
    }
]

export default placesMichoacan;