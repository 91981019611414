import React from "react";

function CardServices(props) {
    return (
        <div className="col my-3 p-0">
            <div className="services rounded card m-auto">
                <div className="icon-container pb-3 pt-4">
                    {props.iconService}
                </div>

                <div className="card-content card-body">
                    <p className="card-service text-center my-2 py-2">{props.service}</p>
                    <p className="card-text text-center py-4 mt-2">{props.serviceDescription}</p>
                </div>
            </div>
        </div>
    );
}

export default CardServices;