import React from "react";

// import Header from "./Header"
import StartSection from "./StartSection";
import Services from "./Services";
import PageInfo from "./PageInfo";
import MisionVision from "./MisionVision";
import WorkPlacesGdl from "./WorkPlacesGdl";
import WorkPlacesQro from "./WorkPlacesQro";
import WorkPlacesGto from "./WorkPlacesGto";
import WorkPlacesMichoacan from "./WorkPlacesMichoacan";
import WorkPlacesMty from "./WorkPlacesMty";
import Contact from "./Contact";
import Footer from "./Footer";
import Titles from "./Titles";

function App() {
    return (
        <div>
            <StartSection />
            <Services />
            <PageInfo />
            <MisionVision />
            <Titles subTitle="¿En donde trabajamos?" clases="container text-center mt-5" />
            <WorkPlacesGdl />
            <WorkPlacesQro />
            <WorkPlacesGto />
            <WorkPlacesMichoacan />
            <WorkPlacesMty />
            <Contact />
            <Footer />
        </div>
    );
}

export default App;