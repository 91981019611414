import React from "react";

function Footer() {

    const year = new Date().getFullYear();

    return(
        <div className="text-center mt-3 bg-dark py-2">  
            <p className="m-auto" style={{color: "white"}}>&copy; {year} SkyLift Ascensores, S.A. de C.V.</p>
        </div>
    );
}

export default Footer;