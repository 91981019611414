import React from "react";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";

export const Mailer = () => {
    
    const sendEmail = (event) =>{
        event.preventDefault();

        emailjs.sendForm("service_pbsa7pb", "template_uz7y7mn", event.target, "7v8Uf9hJ9PjN90c4S")
        .then((result) => {

            Swal.fire({
                title: "Exito",
                text: "Correo enviado correctamente",
                icon: "success",
                timer: 4000
            })
        }, (error) => {

            Swal.fire({
                title: "Lo sentimos:(",
                text: "Ocurrió un error con el envío, inténtalo de nuevo",
                icon: "error",
                timer: 4000
            })
        })
    }

    return (
        <div className="col container">
            <h3 className="mb-4 d-flex justify-content-center">Envianos un correo electronico</h3>
            <form onSubmit={sendEmail} id="form" className="form needs-validated" method="POST">
                <div className="mb-3">
                    <label htmlFor="Name" className="form-label">Nombre</label>
                    <input type="text" name="name" className="formulario form-control form-control-sm" id="Name" required />
                </div>

                <div className="mb-3">
                    <label htmlFor="email" className="form-label">Correo electronico</label>
                    <input type="email" name="email" className="formulario form-control form-control-sm" id="email" placeholder="ejemplo@gmail.com" required />
                </div>

                <div className="mb-3">
                    <label htmlFor="content" className="form-label">Asunto</label>
                    <textarea className="formulario form-control" name="content" id="content" rows="3" required></textarea>
                </div>

                <button type="sumbit" className="btn btn-success">Enviar</button>
            </form>
        </div>
    );
}
