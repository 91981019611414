import React from "react";

function SocialNetwork(props) {
    return(
        <div className="social-networks d-flex container mb-3 col">
            <p className="social-network-icon me-3"><a href={props.linkTo} style={{textDecoration: "none", color: props.iconColor}}>{props.icon}</a></p>
            
            <div className="m-auto w-100">
                <a href={props.linkTo} target="_blank" rel="noopener noreferrer" style={{textDecoration: "none", color: "black"}}>
                    <h5 className="m-0">{props.namePage}</h5>
                    <p className="social-network-username m-0">{props.userName}</p>
                </a>
            </div>
        </div>
    );
}

export default SocialNetwork;