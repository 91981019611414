import React from "react";

function Titles(props) {
    return(
        <div className={props.clases}>
            <h2 style={{fontSize: "2rem"}} >{props.subTitle}</h2>
            <hr className="border border-2 border-success opacity-100 m-auto mb-3" style={{width: "80%"}} />
        </div>
    );
}

export default Titles;