const placesQro = [
    {
        id: "1",
        img: "./assets/JeriquillaTower-qro.jpg",
        name: "Jeriquilla Tower",
        alt: "Jeriquilla Tower",
        direction: "Calle Sta. Teresa 148, Santa Fé, 76230 Juriquilla, Qro."
    },
    {
        id: "2",
        img: "./assets/TorreQ7001-qro.jpg",
        name: "Torre I y II Q7001",
        alt: "Torre Q7001",
        direction: "Blvd. Bernardo Quintana 7001, 76090 Santiago de Querétaro, Qro."
    },
    {
        id: "3",
        img: "./assets/Torre100-qro.jpg",
        name: "Torre 100 Central Park",
        alt: "Torre 100",
        direction: "76090, Centro Sur, 76090 Santiago de Querétaro, Qro."
    },
    {
        id: "4",
        img: "./assets/Intersur-qro.jpg",
        name: "Intersur Empresarial",
        alt: "Intersur",
        direction: "Av Fray Luis de León 8071, Centro Sur, 76090 Santiago de Querétaro, Qro.s"
    },
    {
        id: "5",
        img: "./assets/TheVillage-qro.jpg",
        name: "The Village",
        alt: "The Village",
        direction: "Av. de las Ciencias 3015-local 214, Santa Fé, 76230 Juriquilla, Qro."
    },
    {
        id: "6",
        img: "./assets/AlterraTowes-qro.jpg",
        name: "Alterra Towers",
        alt: "Alterra Towers",
        direction: "Venta del Refugio 315, El Refugio, 76146 Santiago de Querétaro, Qro."
    },
    {
        id: "7",
        img: "./assets/TeatroMetropolitano-qro.png",
        name: "Teatro Metropolitano",
        alt: "Teatro Metropolitano",
        direction: "Paseo de las Artes #1531 - B, Blvd. Bernardo Quintana 1531, Centro Sur, 76090 Santiago de Querétaro, Qro."
    },
    {
        id: "8",
        img: "./assets/CoorporativoBlanco-qro.png",
        name: "Coorporativo Blanco",
        alt: "Coorporativo Blanco",
        direction: "Calle Av. Prolongación Tecnológico #950, Col. Desarrollo San Pablo, CP. 76130, San Tiago de Querétaro, Querétaro."
    }
]

export default placesQro; 