import React from "react";

import placesMichoacan from "../wpMichoacan";
import CreateCardPlaces from "./CreateCardPlaces";

function WorkPlacesMichoacan() {
    return(
        <div className="move-up container-places container album py-5">
            <div className="container">
                <div className="text-center justify-content-center">
                    <h3>Michoacan</h3>
                    <hr className="border border-warning border-2 opacity-50 m-auto mb-4" style={{width: "60%"}}/>
                </div>
                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 g-3">
                    {placesMichoacan.map(CreateCardPlaces)}
                </div>
            </div>
        </div>
    );
}

export default WorkPlacesMichoacan;