import React from "react";

import CardPlaces from "./CardPlaces";

function CreateCardPlaces(place) {
    return(
        <CardPlaces
            key={place.id}
            img={place.img}
            name={place.name}
            alt={place.alt}
            direction={place.direction}
        />
    );

}

export default CreateCardPlaces;