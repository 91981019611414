import React from "react";

function CardMnV(props) {

  return(

    <div className="col-md-6 pb-4 text-center">
      <div className="h-100 p-5 bg-light border rounded-3 shadow">  
        <div className="pb-2 fs-1">
          {props.icon}
        </div>
        <h2 className="mb-3">{props.title}</h2>
        <p className="MVinfo">{props.content}</p>
      </div>
    </div>

  );

}

export default CardMnV; 
