import React from "react";

import { Mailer } from "./ContactForm";
import SocialNetworks from "./AllSocialNetworks";

function Contact() {
    return(
        <div className="container move-up" id="contact-section">
            <div className="text-center justify-content-center my-5">
                <h3>Ponte en contacto con nostros</h3>
                <hr className="border border-2 border-success opacity-50 m-auto mb-4" style={{width: "80%"}} />
            </div>
            <div className="d-flex row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2 g-3" style={{width: "100%"}}>
                <SocialNetworks />
                <Mailer />
            </div>
        </div>
    );
}

export default Contact;
