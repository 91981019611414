import React from "react";

import SocialNetwork from "./SocialNetwork";

function SocialNetworks() {
    return(
        <div className="col container mb-4">
            <h3 className="mb-4 d-flex justify-content-center">Visita nuestras redes sociles</h3>
            <SocialNetwork icon={<i className="bi bi-whatsapp"></i>} iconColor="#00bb2d" namePage="WhatsApp" userName="+52 33 1401 7519" linkTo="https://api.whatsapp.com/send?phone=3314017519" />
            <SocialNetwork icon={<i className="bi bi-instagram"></i>} iconColor=" #E1306C" namePage="Insagram" userName="@sky_lift_ascensores" linkTo="https://www.instagram.com/sky_lift_ascensores/?hl=es" />
            <SocialNetwork icon={<i className="bi bi-facebook"></i>} iconColor=" #3b5998" namePage="Facebook" userName="SKY LIFT Ascensores" linkTo="https://es-la.facebook.com/Skyliftascensores/" />
        </div>
    );
}

export default SocialNetworks;