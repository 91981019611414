const placesGdl = [
    {
        id: "1",
        img: "./assets/TorreIcon23-gdl.jpg",
        name: "Torre Icon 23",
        alt: "Torre Icon 23",
        direction: "Av. Empresarios 255, Puerta de Hierro, 45116 Zapopan, Jal."
    },
    {
        id: "2",
        img: "./assets/LaSebastiana-gdl.jpg",
        name: "La Sebastiana",
        alt: "La sebastiana",
        direction: "Av Pablo Neruda 3273, Providencia 4a. Secc, 44639 Guadalajara, Jal."
    },
    {
        id: "3",
        img: "./assets/HospitalVAllartaMedicalCenter-gdl.jpg",
        name: "Hospital Vallarta Medical Center",
        alt: "Hospital Vallarta Medical Center",
        direction: "Av Los Tules 136, Díaz Ordaz, 48310 Puerto Vallarta, Jal."
    },
    {
        id: "4",
        img: "./assets/PlazaLopezCotilla-gdl.jpg",
        name: "Plaza Lopez Cotilla",
        alt: "Plaza Lopez Cotilla",
        direction: "C. Manuel López Cotilla 373, Zona Centro, 44100 Guadalajara, Jal."
    },
    {
        id: "5",
        img: "./assets/Joyalia-gdl",
        name: "Joyalia",
        alt: "Joyalia",
        direction: "Calle Dionisio Rodríguez 125 A, San Juan de Dios, Guadalajara Jalisco."
    },
    {
        id: "6",
        img: "./assets/TorrePlata-gdl",
        name: "Torre Plata",
        alt: "Torre Plata",
        direction: "Av. Universidad 604, Puerta Plata, 45136 Zapopan, Jal."
    },
    {
        id: "7",
        img: "./assets/Teleton-gdl.jpg",
        name: "Teletón",
        alt: "Teletón",
        direction: "C. Copal 4575, 44980 Guadalajara, Jal."
    }
]

export default placesGdl;