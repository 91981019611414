import React from "react";
import CardServices from "./CardServices";
import Titles from "./Titles";

function Services() {
    return (
        <div className="container-services container m-auto">
            <Titles subTitle="Servicios" clases="title-services container text-center" />
            <section className="services-container row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 g-4">
                <CardServices iconService={<i className="fa-solid fa-hammer"></i>} service="Reparaciones" serviceDescription="Evaluamos y Reparamos cualquiera de tus equipos."/>
                <CardServices iconService={<i className="fa-solid fa-wrench"></i>} service="Instalaciones" serviceDescription="Instalamos cualquiera de tus equipos de carga."/>
                <CardServices iconService={<i className="fa-solid fa-screwdriver-wrench"></i>} service="Mantenimiento" serviceDescription="Le damos mantenimiento a todos tus equipos."/>
                <CardServices iconService={<i className="fa-solid fa-cart-shopping"></i>} service="Ventas" serviceDescription="Compra tus equipos con nosotros al mejor precio"/>
            </section>
        </div>
    );
}

export default Services;