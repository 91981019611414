import React from "react";
import CreateCardMnV from "./CardMnV";
import Valores from "./CardValores";

function MisionVision() {
    return (
        <div className="move-up container-mv container row align-items-md-stretch m-auto justify-content-md-center">
            <CreateCardMnV icon={<i className="mIcon fa-solid fa-flag"></i>} title="Misión" content="SKY LIFT ASCENSORES es una organización dedicada al mantenimiento, instalación, remodelación, y venta de ascensores y escaleras, cumpliendo los estándares de calidad y servicio con nuestros clientes, brindando los medios para una correcta transportación vertical, para desplazar personas o mercancías con una mayor confianza y seguridad."/>
            <CreateCardMnV icon={<i className="vIcon fa-solid fa-lightbulb"></i>} title="Visión" content="Ser una empresa líder que logre satisfacer todas las necesidades y expectativas del mercado en transportación vertical con procesos flexibles, agiles, competitivos, con alta calidad y profesionalismo en el servicio."/>
            <Valores icon={<i className="fa-solid fa-gem"></i>} title="Valores" val1="Responsabilidad; Es unos de los valores más importantes de nuestra empresa por el compromiso que tenemos hacia nuestros clientes en conjunto." val2="Compromiso; Realizamos nuestro trabajo con responsabilidad, ofreciendo lo mejor de nosotros y un excelente servicio cumpliendo con los objetivos." val3="Profesionalismo y Calidad; Ofrecer un servicio con la mejor calidad a través del optimo desempeño de nuestros trabajadores ingenieros, con una capacitación constante."/>
        </div>
    );
}

export default MisionVision;