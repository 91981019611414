import React from "react";

function StartSection() {

    return(
        <div id="carouselExampleFade" className="start-section container carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active rounded" data-bs-interval="5000" style={{height: "600px", backgroundSize: "cover", backgroundImage: `url("./assets/bg1.jpg")` }}>
                    <img className="start-logo mt-2 ms-4" src="./assets/logo-sky-lift-vector.png" alt="Logo SkyLIft" height="70px" width="180px" />

                    <div className="container-info cont-info-1 container">
                        <h1 className="title mb-3">Ascensores</h1>
                        <p className="presentation-text mb-4">Ascensores convencionales, de lujo, panoramicos y montacargas.</p>
                        <a className="" style={{textDecoration: "none"}} href="#contact-section"><button type="button" className="start-button btn mt-3">Contactanos <i className="bi bi-chat-text"></i></button></a>
                    </div>
                </div>

                <div className="carousel-item rounded" data-bs-interval="5000" style={{height: "600px", backgroundSize: "cover", backgroundImage: `url("./assets/bg2.jpg")` }}>
                    <img className="start-logo mt-2 ms-4" src="assets/logo-sky-lift-vector.png" alt="Logo SkyLIft" height="70px" width="180px"/>

                    <div className="container-info container">
                        <h2 className="title mb-3">Escaleras Electricas</h2>
                        <p className="presentation-text mb-4">Escaleras electricas, rampas electricas y bandas trasnsportadoras</p>
                        <p className="start-button-container mt-3"><a className="start-button btn btn-lg btn-primary" href="#contact-section">Contactanos <i className="bi bi-chat-text"></i></a></p>
                    </div>
                </div>
            </div>

            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
            
        </div>
    );
}

export default StartSection;