import React from "react";

import Titles from "./Titles";

function PageInfo() {
    return(
        <div className="container move-up my-4 py-1">
            <Titles subTitle="Acerca de nosotros..." clases="container text-center mb-5" />
            <div className="container w-75 ms-0 mb-5">
                <h2>¿Quienes Somos?</h2>
                <hr className="border border-2 border-success opacity-100" style={{width: "20%"}} />
                <p style={{fontSize: "1.1rem"}}>SKY LIFT es una empresa 100% mexicana dedicada a la instalación, reparación, mantenimiento, remodelación y venta de ascensores y escaleras mecanicas, comprometidos a satisfacer las necesidades de sus diversos clientes.</p>
            </div>

            <div className="container w-75 me-0 text-end">
                <h2>¿Por qué nosotros?</h2>
                <hr className="border border-2 border-success opacity-100" style={{width: "20%", marginLeft: "80%"}} />
                <p style={{fontSize: "1.1rem"}}>Contamos con un gran catálogo de equipos y un personal altamente capacitado para brindar nuestros servicios, SKY LIFT es una empresa dedicada a prestar sus servicios de mantenimiento e instalación a ascensores y escaleras mecanicas, contamos con seguro de responsabilidad civil y además ofrecemos nuestros servicios en cualquier estado de la Republica Mexicana y cubrimos llamadas las 24 horas del día los 365 dias del año.</p>
            </div>
        </div>
    );
}

export default PageInfo;