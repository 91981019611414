const placesMty = [
    {
        id: "1",
        img: "./assets/HoteIstay.png",
        name: "Hotel istay",
        alt: "Hotel istay",
        direction: "José María Morelos 191, Centro, 64000 Monterrey, N.L" 
    }
]

export default placesMty;