import React from "react";

function CardPlaces(props) {
    return(
        <div className="place-container col">
            <div className="card shadow-sm">
                <p className="d-none">{props.id}</p>
                <img width="100%" height="225" src={props.img} className="card-img-top" alt={props.alt}/>
                <div className="card-body">
                    <h4 className="pb-2">{props.name}</h4>
                    <p className="fw-bold mb-2">Dirección</p>
                    <p className="card-text">{props.direction}</p>
                </div>
            </div>
        </div>
    );
}

export default CardPlaces;