import React from "react";

function Valores(props) {
    return (
        <div className="col-md-10 py-4 text-align-justify">
            <div className="h-100 p-5 bg-light border rounded-3 shadow">
                <div className="pb-2 fs-1 text-center">
                    {props.icon}
                </div>
                <h2 className="text-center mb-3">{props.title}</h2>
                <p className="MVinfo">{props.val1}</p>
                <p className="MVinfo">{props.val2}</p>
                <p className="MVinfo">{props.val3}</p>
            </div>
        </div>
    );
}

export default Valores;