const placesGto = [
    {
        id: "1",
        img: "./assets/PlazaMayor-gto.jpg",
        name: "Plaza Mayor",
        alt: "Plaza Mayor",
        direction: "Blvd. Juan Alonso de Torres Pte. 2002, Valle del Campestre, 37150 León, Gto."
    },
    {
        id: "2",
        img: "./assets/ViaAlta-gto.jpg",
        name: "Via Alta Salamanca",
        alt: "Via Alta",
        direction: "Cam. a Mancera 214, Las Glorias, 36766 Salamanca, Gto."
    },
    {
        id: "3",
        img: "./assets/HotelSilao-gto.jpeg",
        name: "Hotel Silao",
        alt: "Hotel Silao",
        direction: "Libramiento Nte. 1335, Sopeña, 36119 Silao, Gto."
    },
    {
        id: "4",
        img: "./assets/HolidayInn-gto.jpg",
        name: "Holiday inn",
        alt: "Hotel Holiday inn",
        direction: "Blvd. Faja de Oro 1129, Los Rangeles, 36740 Salamanca, Gto."
    },
    {
        id: "5",
        img: "./assets/GaleriasPlaza-gto.jpg",
        name: "Galerias Plaza",
        alt: "Galerias Plaza",
        direction: "Av. Siglo XXI No.555, 36823 El Copalillo, Gto."
    }
]

export default placesGto; 